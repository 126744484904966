<template>
    <div>
        <el-input v-model="input"
                  placeholder="请输入内容"></el-input>
    </div>
</template>

<script>
    export default {
        name: 'FunctionCreate',
        data () {
            return {
                input: 'Function Create'
            }
        },
        components: {

        },
        methods: {

        }
    }
</script>

<style>
</style>
